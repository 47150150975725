.error-404-page{
  background-image: url('/assets/wagielektroniczne/img/sites/error404.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100vh - 76px);

  .error-text{
    margin-bottom: 6rem;
  }
  p{
    @include fontSize(22px);
    color: $error-color;
  }
}