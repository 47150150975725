.slider{
  position: relative;

  .slide-item{
    height: 60vh;
    @extend .background-parallax;
    .slider-sub-title{
      color: $white-color;
      font-weight: 500;
      @include fontSize(14px);
      text-shadow: .5px .5px $main-color;
    }
    .slider-text{
      color: $white-color;
      font-weight: 700;
      text-shadow: .5px .5px $main-color;
      text-transform: uppercase;
      @extend .oswald;
      @include fontSize(60px);
      @media only screen and (min-width: 768px) and (max-width: 992px){
        @include fontSize(48px);
      }
      @media only screen and (min-width: 480px) and (max-width: 767px)  {
        @include fontSize(32px);
      }
      @media only screen and (max-width: 479px){
        @include fontSize(28px);
      }
    }
  }
  .arrow{
    background-repeat: no-repeat;
    background-position: 50% 50%;
    &:hover{
      cursor: pointer;
    }
  }
  .pagination{
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $white-color;
    border-radius: 0;
    .next{
      background-image: url('/assets/wagielektroniczne/img/sites/arrow-right.png');
      @extend .arrow;
    }
    .prev{
      background-image: url('/assets/wagielektroniczne/img/sites/arrow-left.png');
      @extend .arrow;
    }
  }

}


.button{
  background-color: $hover-link-background;
  color: $white-color;
  border: 1px solid $hover-link-background;
  &:hover{
    background-color: $white-color;
    color: $hover-link-background;
  }
}