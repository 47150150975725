@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}