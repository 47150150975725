body{
  font-family: 'Poppins', 'Oswald', sans-serif;
}
.poppins{
  font-family: 'Poppins', sans-serif;
}
.oswald{
  font-family: 'Oswald', sans-serif;
}
#main{
  padding-top: 76px;
  @media only screen and(max-width: 1200px){
    padding-top: 0;
  }
}
.dark{
  display: none;
  background-color: rgba(0, 0, 0, .7);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 20;
  @media only screen and (max-width: 992px){
    display: none!important;
  }
}
.background-parallax{
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
a{
  &:hover{
    text-decoration: none;
  }
}
.section-title{
  @include fontSize(24px);
  font-weight: 700;
  span{
    color: $hover-link-background;
  }
}