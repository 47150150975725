.novelty-single{
    a:hover{
        text-decoration:none;
        color:#5fa000;
    }
    .novelty-link{
      background-image: url('/assets/pomiarymetr/img/sites/product-link-green.png');
      background-repeat: no-repeat;
      background-position: center right;
      width:10rem;
      font-size:0.85rem;
      position:absolute;
      bottom:1rem;
      right:0px;
      @include fontSize(16px);
    }
    .novelty-title{
        margin-bottom:0.3rem;
        line-height:1.3;
    }
    .novelty-description{
        margin-bottom:0.3rem;
        color: #464646;
        line-height:1.3;
        @include fontSize(12px);
    }
    .novelty-date{
        margin:0px;
        padding:0px;
        line-height:1;
        font-size:0.75em;
    }
    .novelty-price{
      color: $hover-link-background;
      font-weight: 500;
      span{
        font-weight: 400;
        @include  fontSize(14px);
      }
    }
    .novelty-img{
      background-repeat: no-repeat;
      background-position: 0% 0%;
      background-size: cover;
      height:160px;
    }
    .novelty-bottom{
      border-bottom: 2px solid $grey-color;
      padding-bottom: 1em;
      padding-left:0px;
      padding-right:0px;
    }
}
