.footer-top{
  .company-name{
    @extend .oswald;
    @include fontSize(14px);
    color: $main-color;
  }
  .nav-link{
    @include fontSize(12px);
    font-weight: 500;
    color: $main-color;
  }
}
.footer-bottom{
  .footer-info{
    @include fontSize(12px);
    font-weight: 500;
    color: #959595;
  }
  a{
    color: $hover-link-background;
  }
}