#newses{
  .weight-offer{
    background-color: #f1f1f1;
  }
  .weight-offer-description{
    color: #464646;
    @include fontSize(15px);
  }
  .newses{
    background-image: url('/assets/pomiarymetr/img/sites/news-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    color: $white-color;
    @media only screen and (min-width: 768px){
      margin-top: -6rem;
    }
  }
  .news-header{
    font-weight: 700;
    @include fontSize(25px);
  }
  .news-title{
    @include fontSize(18px);
    @extend .oswald;
    font-weight: 700;
  }
  .news-content{
    @include fontSize(15px);
    font-weight: 500;
  }
  .news-date{
    @include fontSize(17px);
    @extend .oswald;
  }
}
.slick-dots{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  list-style: none;
  cursor: pointer;
  li{
    width: 30px;
    height: 5px;
    background-color: rgba(255,255,255, .7);
    margin: 0 .5rem;
    &.slick-active{
      background-color: rgba(255,255,255, 1);
    }
  }
}

#services{
  background-color: $main-color;
  background-image: url('/assets/wagielektroniczne/img/sites/uslugi-background.png');
  background-repeat: no-repeat;
  background-position: left center;
  color: $white-color;

  .service-header{
    @extend .oswald;
    @include fontSize(24px);
    font-weight: 700;
    border-bottom: 2px solid #464646;
  }
  .service-link{
    @include fontSize(12px);
    color: $white-color;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
  }
}