#static-page{
  background-color: $white-color;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  height: 50vh;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 0;
    top: 0;
    opacity: .9;
    background: linear-gradient(to right,$hover-link-background, 60%,rgba(0, 0, 0, 0) 100%);
  }
}

.page-content{
  img{
    margin: 15px;
  }
  a{
    color: $hover-link-background;
    &:hover{
      color: $main-color;
      text-decoration: underline;
    }
  }
}
.faq-question{
  color: $main-color;
  font-weight: 700;
  &:hover{
    color: $hover-link-background;
  }
}

.faqResponse{
  a{
    color: $hover-link-background;
    &:hover{
      color: $main-color;
    }
  }
}

.photos{
  .photo-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 250px;
  }
  .photo-title{
    @extend .oswald;
    @include fontSize(16px);
    font-weight: 700;
    color: $main-color;
  }
}

.posts{
  .post-url{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 250px;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
      bottom: 0;
      right: 0;
      background-color: $main-color;
      background-image: url('/assets/wagielektroniczne/img/sites/white-arrow.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
  .post-title{
    @extend .oswald;
    @include fontSize(16px);
    font-weight: 700;
    color: $main-color;
  }
}
.post-photo{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 500px;
}