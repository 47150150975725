.page{
  position: relative;
  .bottom-position{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 58.333333%;
  }
}
#contact-page{
  background-color: $white-color;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  height: 50vh;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 0;
    top: 0;
    opacity: .9;
    background: linear-gradient(to right,$hover-link-background, 60%,rgba(0, 0, 0, 0) 100%);
  }
}
.contacts-list{
  color: $hover-link-background;
  font-weight: 700;
  @extend .oswald;
  &:hover{
    color: $main-color;
  }
}
.contact-email{
  color: $hover-link-background;
  &:hover{
    color: $main-color;
  }
}
.white-space{
  background-color: $white-color;
}

.page-subtitle{
  @include fontSize(14px);
  color: $white-color;
  font-weight: 500;
}
.page-title{
  @include fontSize(60px);
  @extend .oswald;
  color: $white-color;
  text-transform: uppercase;
  font-weight: 300;
  @media only screen and (min-width: 562px) and (max-width: 992px){
    @include fontSize(48px);
  }
  @media only screen and (min-width: 481px) and (max-width: 561px){
    @include fontSize(32px);
  }
  @media only screen and (max-width: 480px){
    @include fontSize(24px);
  }
  span{
    font-weight: 700;
  }
}
.right-sidebar{
  background-color: #f1f1f1;
  .right-aside-title{
    border-bottom: 2px solid $main-color;
    font-weight: 700;
    @extend .oswald;
    @include fontSize(24px);
  }
}
.service-name{
  @include fontSize(12px);
  font-weight: 700;
  color: #464646;
}
.service-phone{
  @include fontSize(14px);
  font-weight: 500;
  color: #464646;
}
#map{
  height: 80vh;
}
.company-sub-title{
  @include fontSize(18px);
}
.company-title{
  @extend .oswald;
  @include fontSize(18px);
  font-weight: 700;

}